import React, { useEffect } from 'react'

import { useStore } from "../../@core/scenes/mainStore"
import LANGUAGES from "../../Const/languages"
import gsap from 'gsap'
import { ScrollToPlugin } from '../../vendors/gsap/ScrollToPlugin'

const SkipIntro = () => {
  const LANG = useStore(state => state.language)


  const clickHandler = (e) => {
    let targetElem = document.querySelector(".section-select-visionnaires-entreprises")
    gsap.to(window, {
      scrollTo: {
        y: targetElem,
        autoKill: false
      },
      duration: 1
    });
  }

  useEffect(()=> {
    gsap.registerPlugin(ScrollToPlugin)
  }, [])

  return (
    <div className="skip-intro" onClick={clickHandler} role="button" tabIndex={0}>
      {LANGUAGES[LANG].skipIntro}
    </div>
  )
}

export default SkipIntro
