import React, { useEffect, useState } from 'react'
import gsap from 'gsap'
import { ScrollToPlugin } from '../../vendors/gsap/ScrollToPlugin'
import { useStore } from '../../@core/scenes/mainStore'

export const SideBarDots = () => {

  const sectionActive = useStore(state => state.homeSectionActive)

  const anchors = [
    "#topSection",
    "#Text_3",
    ".parce-que-1",
    ".section-criteres",
    ".section-select-visionnaires-entreprises"
  ]

  return (
    <div className="sidebar-vertical">
      {
        anchors.map((value, index) => {
          let isActive = false
          if (index === sectionActive) isActive = true
          return <Puce
          anchor={value}
          key={index}
          active={isActive}
          index={index}
          />
        })
      }

    </div>
  )
}



const Puce = ({active, anchor, index, ...props})=> {
  const sectionActive = useStore(state => state.homeSectionActive)

  useEffect(()=> {
    gsap.registerPlugin(ScrollToPlugin)
  }, [])

  const clickHandler = (e) => {
    useStore.setState({ homeSectionActive: index })
    let targetElem = document.querySelector(anchor)
    gsap.to(window, {
      scrollTo: {
        y: targetElem,
        autoKill: false
      },
      duration: 1
    });
  }

  // const mouseEnterHandler = () => {
  //   setCurrent(true)
  // }
  // const mouseLeaveHandler = () => {
  //   if (index !== sectionActive) setCurrent(false)
  // }

  return (
    <div className="puce"
      onClick={clickHandler}
      // onMouseEnter={mouseEnterHandler}
      // onMouseLeave= {mouseLeaveHandler}

    >
      {
        active ?
          <svg width="36" height="20" viewBox="0 0 36 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14" cy="10" r="4" fill="#48E6F0"/>
            <circle cx="14" cy="10" r="9.5" stroke="#48E6F0"/>
            <line x1="24" y1="10" x2="36" y2="10" stroke="#48E6F0"/>
          </svg>
        :
          <svg width="20" height="8" viewBox="0 0 20 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" fill="white"/>
            <line opacity="0.5" x1="14" y1="4" x2="20" y2="4" stroke="white"/>
          </svg>
      }
    </div>
  )
}
