import React from "react"
import { useStore } from "../../@core/scenes/mainStore"
import LANGUAGES from "../../Const/languages"
import SelectMainCategories from "./selectMainCategories"

export default function VisionnairesEntreprises() {
  const LANG = useStore(state => state.language)

  return (
    <div className="section-select-visionnaires-entreprises section-dark">
      <div className="grid">
        <div className="bloc-content">
          <p className="discover-txt">
            {LANGUAGES[LANG].discoverOurSelection}
          </p>

          <p className="click-txt">
            {LANGUAGES[LANG].clickToDiscover}
          </p>

          <SelectMainCategories />
        </div>
      </div>
    </div>
  )
}
