import React, { useEffect } from "react"
import Layout from "../components/layout"
import CriteresSelection from "../components/sections/criteresSelection"
import VisionnairesEntreprises from "../components/sections/visionnairesEntreprises"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "../vendors/gsap/ScrollToPlugin"
import { homeSectionsAnimations } from "../@core/scrollTriggerAnimations/homeSectionsAnimations.js"
import { ScrollInfo } from "../components/ui/scrollInfo"
import { useStore } from "../@core/scenes/mainStore"
import { Helmet } from "react-helmet"
import { SideBarDots } from "../components/ui/SideBarDots"
import LANGUAGES from "../Const/languages"
import SkipIntro from "../components/ui/skipIntro"

export default function Home({ location }) {
  const LANG = useStore(state => state.language)

  const pointsCloudIsReady = useStore(state => state.pointsCloudIsReady)
  // Logo color
  useEffect(() => {
    useStore.setState({ theme: "white" })
  }, [])

  // Scroll Trigger des Textes et différentes sectiopn
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    if (pointsCloudIsReady) {
      homeSectionsAnimations()
      // launch Clouds ScrollTrigger
      const mainCloud = useStore.getState().mainCloudGL
        setTimeout(()=>mainCloud.ScrollAnimation(), 700 )
    }

  }, [pointsCloudIsReady])

  // check location, if from visionnaire go to section Visionnaires/Entreprises
  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin)
    if (location.state && location.state.fromVisionnaire) {
      let targetElem = document.querySelector(
        ".section-select-visionnaires-entreprises"
      )
      gsap.to(window, {
        scrollTo: {
          y: targetElem,
          autoKill: false,
        },
        duration: 0.1,
      })
    }
  }, [location.state])

  return (
    <Layout localClass="home" showPointClouds={true}>
      <Helmet>
        <title>Accueil</title>
      </Helmet>
      {/* <div ref={canvasRef} className="canvasClouds"></div> */}
      <ScrolledContent>
        <div className="separator section-one contentMainContainer" id="section_1">
          {/*  TEXT  */}
          <p className="scroll-text home-text-1 text-gsap" id="Text_1">
            {LANGUAGES[LANG].homeTxt_1}<br/>{LANGUAGES[LANG].homeTxt_1Br}
          </p>
          <h2 className="scroll-text home-text-2 text-gsap" id="Text_2">
            {LANGUAGES[LANG].homeWelcome}
            <br />
            <span className="brand-1618">{LANGUAGES[LANG].homeWelcomeParis}</span>
          </h2>
        </div>
        <div className="separator contentMainContainer section-two"></div>
        <div className="separator contentMainContainer section-three" id="section_3">
          <p className="scroll-text home-text-full home-text-3" id="Text_3">
            <span className="brand-1618">{LANGUAGES[LANG].homeTxt3}</span>
            <br />
            {LANGUAGES[LANG].homeTxt3_2}
            <br />
            {LANGUAGES[LANG].homeTxt3_3}
          </p>
        </div>
        <div className="separator contentMainContainer section-four" id="section_4">
          {/* 1 */}
          <p className="scroll-text text-gsap home-text-4 parce-que-1">
            <span className="brand-1618">{LANGUAGES[LANG].homeParceque}</span>
            <br />
            {LANGUAGES[LANG].homeTxt4}
            <br />{LANGUAGES[LANG].homeTxt4_1}
          </p>
          {/* 2 */}
          <p className="scroll-text text-gsap home-text-4 parce-que-2">
            <span className="brand-1618">{LANGUAGES[LANG].homeParceque}</span>
            <br />
            {LANGUAGES[LANG].homeTxt5}
            <br /> {LANGUAGES[LANG].homeTxt5_1}
          </p>
          {/* 3 */}
          <p className="scroll-text text-gsap home-text-4 parce-que-3">
            <span className="brand-1618">{LANGUAGES[LANG].homeParceque2}</span>
            <br />
            {LANGUAGES[LANG].homeTxt6}<br />
            {LANGUAGES[LANG].homeTxt6_1}
          </p>
          {/* 4 */}
          <p className="scroll-text text-gsap home-text-4 parce-que-4">
            <span className="brand-1618">{LANGUAGES[LANG].homeParceque}</span>
            <br />
            {LANGUAGES[LANG].homeTxt7}<br />
            {LANGUAGES[LANG].homeTxt7_1}
          </p>
          <p
            className="scroll-text home-text-full text-gsap home-text-6"
            id="Text_6"
          >
            <span className="brand-1618" id="Text_4_Parceque">
            {LANGUAGES[LANG].homeTxt8}
            </span>
            <br />
            {LANGUAGES[LANG].homeTxt8_1}
            <br />  {LANGUAGES[LANG].homeTxt8_2}
            <br />
            <br />
            <span className="brand-1618 celebrate-txt">{LANGUAGES[LANG].homeTxt9}</span>
          </p>
        </div>
        <SideBarDots />
        {/* Critères de sélection */}
        <CriteresSelection />
        {/* Choix Les Visionnaires / Entreprises */}
        <VisionnairesEntreprises />
        {/* <div className="separator section-thirteen"></div>
        <div className="separator section-fourteen"></div> */}
      </ScrolledContent>
      <ScrollInfo />
      <SkipIntro />
    </Layout>
  )
}

const ScrolledContent = ({ children }) => {
  return (
    <div className="scrolledContentContainer" id="topSection">
      {children}
    </div>
  )
}
