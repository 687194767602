import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { isMobile } from "../../@utils/isMobile"
import { useStore } from "../scenes/mainStore"
gsap.registerPlugin(ScrollTrigger)

export const homeSectionsAnimations = () => {
  // TEXT Elements
  const text_1 = document.getElementById("Text_1")
  const text_2 = document.getElementById("Text_2")
  const text_3 = document.getElementById("Text_3")
  const section_1 = document.getElementById("section_1")
  const section_3 = document.getElementById("section_3")
  const section_4 = document.getElementById("section_4")
  const text_6 = document.getElementById("Text_6")

  gsap.to(text_1,
    {
      opacity:1,
      delay:1.5
    })

  // Bienvenue
  const tl0 = gsap.timeline({
    scrollTrigger: {
      trigger: section_1,
      pin: true,
      scrub: 1,
      start: "top top",
      end: "+=2000",
      toggleActions: "play pause play reset",
      // markers: true
    },
  })

  // Apparition de Bienvenue
  tl0
    .fromTo(
      text_1, {
        opacity: 1,
      }, {
        opacity: 0,
        onReverseComplete: () => useStore.setState({ homeSectionActive: 0 })
      }, "+=.5")
    .fromTo(text_2,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        transform: "translateY(-10px)",
      })
    .to(text_1, {
      transform: "translateY(-20px)",
      opacity: 0,
    })
    .to(text_2, {
      opacity: 0,
      transform: "translateY(-20px)",
      onReverseComplete: () => useStore.setState({ homeSectionActive: 0 })

    })
  // new Timeline pour les scrollTexte Elements
  // toggleAction :  onEnter, onLeave, onEnterBack, and onLeaveBack
  const tl = gsap.timeline(
    {
      scrollTrigger: {
        trigger: section_3,
        pin: true,
        scrub: 1,
        start: "top top",
        end: "+=1000",
        toggleActions: "play pause play reset",
        // markers: true
      },
    },
    "-=1"
  )
  // Apparition de 1.618 Paris
  tl.fromTo(
    text_3,
    {
      opacity: 0,
    },
    {
      opacity: 1,
      duration: .7,
      scale: 0.95,
      onStart: () => useStore.setState({ homeSectionActive: 1 }),
      onReverseComplete: () => useStore.setState({ homeSectionActive: 0 })
    },
    "-=1"
  )
    // disparition de Paris
    .to(
      text_3,
      {
        opacity: 0,
        duration: .75,
        scale: 0.95,
      },
      "+=.5"
    )

  const tl2 = gsap.timeline({
    scrollTrigger: {
      trigger: section_4,
      pin: true,
      scrub: 1,
      start: "top top",
      end: "+=4000",
      toggleActions: "play pause play reset",
      // markers: true
    },
  })
  // Apparition de "Parce que"
  tl2.fromTo(
      ".parce-que-1",
      {
        opacity: 0,
        transform: "translateY(10px)",
      },
      {
        opacity: 1,
        transform: "translateY(0)",
        onStart: () => useStore.setState({ homeSectionActive: 2 }),
        onReverseComplete: () => useStore.setState({ homeSectionActive:1 }),

      }
    )
    .to(
      ".parce-que-1",
      {
        opacity: 0,
        transform: "translateY(-20px)",
      },
      "+=.5"
    )
    .fromTo(
      ".parce-que-2",
      {
        opacity: 0,
        transform: "translateY(10px)",
      },
      {
        opacity: 1,
        transform: "translateY(0px)",
      }
    )
    .to(
      ".parce-que-2",
      {
        opacity: 0,
        transform: "translateY(-20px)",
      },
      "+=.5"
    )
    // parceque 3
    .fromTo(
      ".parce-que-3",
      {
        opacity: 0,
        transform: "translateY(10px)",
      },
      {
        opacity: 1,
        transform: "translateY(0px)",
      }
    )
    .to(
      ".parce-que-3",
      {
        opacity: 0,
        transform: "translateY(-20px)",
      },
      "+=.5"
    )
    // parceque 4
    .fromTo(
      ".parce-que-4",
      {
        opacity: 0,
        transform: "translateY(10px)",
      },
      {
        opacity: 1,
        transform: "translateY(0px)",
      }
    )
    .to(
      ".parce-que-4",
      {
        opacity: 0,
        transform: "translateY(-20px)",
      },
      "+=.5"
    )
    // text 6 "Célébrons"
    .fromTo(
      text_6,
      {
        opacity: 0,
        scale: 0.95,
        transform: "translateY(0px)",
      },
      {
        opacity: 1,
        duration: 1,
      }
    )
    .to(
      text_6,
      {
        opacity: 0,
        duration: 2,
        transform: "translateY(-20px)",
      },
      "+=.5"
    ).fromTo(".skip-intro", {
      opacity: 1,
    }, {
      opacity: 0,
    })

  if (isMobile()) {
    tl2.fromTo(".scroll-info", {
      opacity: 1,
    }, {
      opacity: 0,
    }, "-=1.5")
  }

  // Critères de sélection
  // ********************************************
  // apparition UI critères
  if (!isMobile()) {
    const tl3 = gsap.timeline({
      onStart: () => useStore.setState({ homeSectionActive: 3 }),
      onReverseComplete: () => useStore.setState({ homeSectionActive: 2 }),
      scrollTrigger: {
        trigger: ".section-criteres",
        pin: true,
        scrub: 1,
        start: "top top",
        end: "+=3000",
        toggleActions: "play pause play reset",
        // markers: true
      },
    })
    tl3
    .fromTo(
      ".section-criteres-top",
      {
        opacity: 1,
      },
      {
        opacity: 0,
        display: "none",
        duration: 0.5,
      },
      "+=1"
    )
    // cercle gradient
    .fromTo(".circular-gradient", {
      opacity: 0,
    }, {
      opacity: 0.2,
    })
    // ryzhomes sur la gauche
    .to(
      ".canvasRyzhome",
      {
        transform: "translateX(0)",
        duration: 2,
      },
      "-=1"
    )
    tl3.fromTo(
      ".criteres-ui-list",
      {
        opacity: 0,
        zIndex: -1,
        transform: "translateX(100px)",
      },
      {
        opacity: 1,
        zIndex: 1,
        transform: "translateX(0px)",
        duration: 2,
      },
      "-=1"
    )
    // pause
    .to(".criteres-ui-list", {
      opacity: 1,
      duration: 2,
    })

    .to(".criteres-ui-list", {
      opacity: 0,
      zIndex: -1,

    })
    .fromTo(
      ".experts-ui-list",
      {
        opacity: 0,
        transform: "translateX(100px)",
      },
      {
        opacity: 1,
        transform: "translateX(0px)",
        duration: 2,
      },
    )
    // pause
    .to(".experts-ui-list", {
      opacity: 1,
      duration: 2,
    })
    .fromTo(".section-criteres",{
        opacity: 1,
        duration: 1,
      },
      {
        opacity: 0,
        duration: 1,
        onStart: () => useStore.setState({ homeSectionActive: 4 }),
        onReverseComplete: () => useStore.setState({ homeSectionActive: 3 }),
    })
    .fromTo(".scroll-info", {
      opacity: 1,
    }, {
      opacity: 0,
    }, "-=1.5")

  }

  // Selection Main categories
  const tl4 = gsap.timeline({
    scrollTrigger: {
      trigger: ".section-select-visionnaires-entreprises",
      pin: true,
      scrub: 1,
      start: "top top",
      end: "+=2000",
      toggleActions: "play pause play reset",
      // markers: true
    },
  })


}
